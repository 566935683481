const COMPRESSION_STREAM: string = "CompressionStream"
const supported = COMPRESSION_STREAM in window;

export default async function (input: string): Promise<Uint8Array> {
    try {
        if (supported) {
            const stream = new ReadableStream({
                async start(controller) {
                    controller.enqueue(input);
                    controller.close();
                }
            }).pipeThrough(new TextEncoderStream()).pipeThrough(new (window as { [key: string]: any })[COMPRESSION_STREAM]("gzip"));
            return new Uint8Array(await read(stream));
        }
    } catch { /* do nothing */ }
    return new Uint8Array();
}

async function read(stream: ReadableStream): Promise<number[]> {
    const reader = stream.getReader();
    const chunks: number[] = [];
    let done = false;
    let value: number[] = [];
    while (!done) {
        ({ done, value } = await reader.read());
        if (done) { return chunks; }
        chunks.push(...value);
    }
    return chunks;
}