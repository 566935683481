import {
    DEFAULT_INACTIVITY_COOLING_PERIOD,
    DEFAULT_INACTIVITY_THROTTLE,
    DEFAULT_VISIBILITY_THROTTLE,
    UE_SESSION_EXPIRE,
    UE_SESSION_ACTIVE,
    RESTART_SESSION,
    ONE_MINUTE,
    setSessionStorageItem,
    getSessionStorageItem,
} from "../utils";

export class UserIdleTracker {
    private readonly activityTimeout: number;
    private readonly visiblityTimeout: number;
    private interval: NodeJS.Timeout | undefined;
    private timeOutTracker: NodeJS.Timeout | undefined;
    private handleActivityEvent: () => void;
    private handlePageVisiblityEvent: () => void;
    private handleResumeActivityEvent: () => void;
    private pageVisibleTimeout: NodeJS.Timeout | undefined;
    private activeInterval: NodeJS.Timeout | undefined;
    private startSession: () => void;
    private stopSession: (clearId: boolean) => void;

    constructor(startSession: () => void, stopSession: () => void) {
        this.activityTimeout = DEFAULT_INACTIVITY_THROTTLE
        this.visiblityTimeout = DEFAULT_VISIBILITY_THROTTLE
        this.interval = undefined
        this.activeInterval = undefined
        this.startSession = startSession
        this.stopSession = stopSession
        this.handleActivityEvent = this.updateSessionExpireTime.bind(this);
        this.handlePageVisiblityEvent = this.pageVisibilityEventHandler.bind(this);
        this.handleResumeActivityEvent = this.resumeActivityTracker.bind(this);
        this.initalizeTracker();
        this.startActivityTracker();
    }
    updateSessionExpireTime() {
        //updating the user activity
        if (this.timeOutTracker) {
            clearTimeout(this.timeOutTracker)
        }
        this.timeOutTracker = setTimeout(() => {
            setSessionStorageItem(UE_SESSION_EXPIRE, (Date.now() + (this.activityTimeout * 1000)).toString());
        }, DEFAULT_INACTIVITY_COOLING_PERIOD)
    }
    startActivityTracker() {
        this.updateSessionExpireTime();

        //interval to track user activity in any tabs
        this.interval = setInterval(() => {
            // restart session when user is idle for 30 mins.
            const activityExpireTime = parseInt(getSessionStorageItem(UE_SESSION_EXPIRE) || '0', 10);
            if (activityExpireTime < Date.now()) {
                setSessionStorageItem(RESTART_SESSION, "true");
                this.stopSession(false);
                this.initalizeActivityResumeTracker();
            }
        }, ONE_MINUTE)

        //interval to track active tabs
        this.activeInterval = setInterval(() => {
            if (!document.hidden) {
                setSessionStorageItem(UE_SESSION_ACTIVE, Date.now().toString());
            }
        }, ONE_MINUTE)
    }
    pageVisibilityEventHandler() {
        // restart session when user went to another tab or window or minimized.
        if (document.hidden) {
            this.pageVisibleTimeout = setTimeout(() => {
                
                const lastActiveTime = parseInt(getSessionStorageItem(UE_SESSION_ACTIVE) || '0', 10);
                if (Math.floor((Date.now() - lastActiveTime) / 1000) >= this.visiblityTimeout) {
                    setSessionStorageItem(RESTART_SESSION, "true");
                    this.stopSession(false);
                    this.initalizeActivityResumeTracker();
                }
            }, this.visiblityTimeout * 1000)
        } else {
            const lastActiveTime = parseInt(getSessionStorageItem(UE_SESSION_ACTIVE) || '0', 10);
            if (Math.floor((Date.now() - lastActiveTime) / 1000) < this.visiblityTimeout) {
                clearTimeout(this.pageVisibleTimeout)
            }
        }
    }

    resumeActivityTracker(): void {
        if (!document.hidden) {
            const restartSession = getSessionStorageItem(RESTART_SESSION);
            // start session when the user comes back on the session performing tab.
            if (restartSession && restartSession === "true") {
                sessionStorage.removeItem(RESTART_SESSION);
                this.cleanUpActivityResumeTracker();
                this.startSession();
            }
        }
    }
    initalizeTracker() {
        // event listeners handle session behaviour
        window.addEventListener("click", this.handleActivityEvent)
        window.addEventListener("scroll", this.handleActivityEvent)
        window.addEventListener("keydown", this.handleActivityEvent)
        window.addEventListener("visibilitychange", this.handlePageVisiblityEvent)
    }
    cleanUpTracker() {
        // clearing event listeners, intervals and timeouts
        clearInterval(this.interval)
        clearInterval(this.activeInterval)
        clearTimeout(this.pageVisibleTimeout)
        window.removeEventListener("click", this.handleActivityEvent)
        window.removeEventListener("scroll", this.handleActivityEvent)
        window.removeEventListener("keydown", this.handleActivityEvent)
        window.removeEventListener("visibilitychange", this.handlePageVisiblityEvent)
    }

    initalizeActivityResumeTracker() {
        window.addEventListener("visibilitychange", this.handleResumeActivityEvent)
        window.addEventListener("click", this.handleResumeActivityEvent)
        window.addEventListener("scroll", this.handleResumeActivityEvent)
        window.addEventListener("keydown", this.handleResumeActivityEvent)
    }
    cleanUpActivityResumeTracker() {
        window.removeEventListener("visibilitychange", this.handleResumeActivityEvent);
        window.removeEventListener("click", this.handleResumeActivityEvent);
        window.removeEventListener("scroll", this.handleResumeActivityEvent);
        window.removeEventListener("keydown", this.handleResumeActivityEvent);
    }
}