
interface LayoutShiftAttribution {
    node?: Node;
    previousRect: DOMRectReadOnly;
    currentRect: DOMRectReadOnly;
    navigationId?: string;
}
interface LayoutShift extends PerformanceEntry {
    value: number;
    sources: LayoutShiftAttribution[];
    hadRecentInput: boolean;
    navigationId?: string;
}

let lcpData: any;
let fcpData: any;
let clsData: any;

export const getPerformanceData = (): object => {

    const observerFcp = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
            fcpData = entry.startTime;
        });
    });

    observerFcp.observe({ type: "paint", buffered: true });

    const observerLcp = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        const lastEntry = entries[entries.length - 1]; // Use the latest LCP candidate
        lcpData = lastEntry;
    });
    observerLcp.observe({ type: "largest-contentful-paint", buffered: true });

    observerLcp;
    observerFcp;

    let cumulativeLayoutShiftScore = 0;

    // Create a PerformanceObserver to observe 'layout-shift' entries

    const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
            // Cast the entry to LayoutShift
            const layoutShiftEntry = entry as LayoutShift;
            // Only consider layout shifts that were not caused by user interactions
            if (!layoutShiftEntry.hadRecentInput) {
                // Accumulate the layout shift score
                cumulativeLayoutShiftScore += layoutShiftEntry.value;
            }
        });
    });

    // Start observing layout shifts
    observer.observe({ type: 'layout-shift', buffered: true });

    // Optionally, stop observing when the page is fully loaded and log the CLS score
    window.addEventListener('load', () => {
        observer.disconnect();
        clsData = cumulativeLayoutShiftScore;
    });

    const performanceData: object = {
        lcp: lcpData,
        fcp: fcpData,
        cls: clsData,
        url: window.location.href.split('?')[0],
        navigation: window.performance.navigation,
        performanceJson: window.performance.toJSON,
    }
    
    return performanceData;
}
