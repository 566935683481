export const CONSOLE_LOG_TYPES = {  
    ALL: 'all',  
    INFO: 'info',  
    WARN: 'warn',  
    ERROR: 'error',  
    DEBUG: 'debug',  
    LOG: 'log',  
    ASSERT: 'assert',  
} as const;  

export type ConsoleLogTypes = (typeof CONSOLE_LOG_TYPES)[keyof typeof CONSOLE_LOG_TYPES];