import bowser from "../../bowser"
import { getPerformanceData } from "./collectPerformance";
import { getNormalizedUrl } from "../utils";

interface BrowserDataInterface {
    browserName?: string,
    os?: string,
    navigatorInfo?: Navigator,
    locale?: string
}

const tpid: string = (Math.random() + 1).toString(36).substring(4);

export interface objectStringAccess {
    [key: string]: string | null
}

// const getLocalStorage = (): object => {
//     const localStorageObject: objectStringAccess = {};
//     for (const key in localStorage) {
//         localStorageObject[key] = localStorage.getItem(key);
//     }
//     return localStorageObject;
// }

const getBowserData = (): bowser.Parser.ParsedResult => {
    return bowser.parse(window.navigator.userAgent)
}

const getMiscBrowserData = (): object => {
    const nVer: string = navigator.appVersion;

    const currentBrowserData: BrowserDataInterface = {
        browserName: "Unknown"
    };

    // browserName = nVer.match(/(firefox|msie|chrome|safari)[/\s]([\d.]+)/ig)[0];
    const browserNameList = nVer.match(/(firefox|msie|chrome|safari)[/\s]([\d.]+)/gi)
    if (browserNameList) {
        currentBrowserData.browserName = browserNameList[0];
    }

    let OSName = "Unknown OS";
    if (nVer.indexOf("Win") !== -1) OSName = "Windows";
    if (nVer.indexOf("Mac") !== -1) OSName = "MacOS";
    if (nVer.indexOf("X11") !== -1) OSName = "UNIX";
    if (nVer.indexOf("Linux") !== -1) OSName = "Linux";

    currentBrowserData.os = OSName;
    currentBrowserData.navigatorInfo = navigator;
    currentBrowserData.locale = navigator.language;

    return currentBrowserData;
}

const getNavigator = (): object => {
    const validNavigatorKeys: string[] = [
        "productSub",
        "vendor",
        "cookieEnabled",
        "appVersion",
        "platform",
        "userAgent",
    ];
    const navigatorObject: any = {};
    let navKey: keyof Navigator;

    for (navKey in navigator) {
        if (validNavigatorKeys.indexOf(navKey) >= 0) {
            navigatorObject[navKey] = navigator[navKey];
        }
    }
    return navigatorObject;
}

export const getSessionUrl = (): string => {
    return "https://webmonitoring.userexperior.online/e/"+tpid;
}

let performanceData: any;

export const getBrowserData = (): object => {
    const miscdata: BrowserDataInterface = getMiscBrowserData();
    const pageTitleElement = document.getElementsByTagName("title")
    performanceData = getPerformanceData();

    return {
        page_title: pageTitleElement.length ? pageTitleElement[0].innerHTML : document.title,
        // local_storage: getLocalStorage(),
        browser_data: getNavigator(),
        url: window.location.href.split('?')[0],
        normalizedUrl: getNormalizedUrl(window.location.href.split('?')[0]),
        browser: miscdata.browserName,
        bowser_data: getBowserData(),
        os: miscdata.os,
        locale: navigator.language,
        viewport: `${window.innerWidth}x${window.innerHeight}`,
        screen_size: `${screen.width}x${screen.height}`,
        density: window.devicePixelRatio,
        tpId: tpid,
        performance: performanceData,
    };
}
