import { __awaiter } from './../../../../../ext/tslib/tslib.es6.js';
import { deserializeArg } from './deserialize-args.js';

function canvasMutation({ event, mutations, target, imageMap, errorHandler, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const ctx = target.getContext('2d');
        if (!ctx) {
            errorHandler(mutations[0], new Error('Canvas context is null'));
            return;
        }
        const mutationArgsPromises = mutations.map((mutation) => __awaiter(this, void 0, void 0, function* () {
            return Promise.all(mutation.args.map(deserializeArg(imageMap, ctx)));
        }));
        const args = yield Promise.all(mutationArgsPromises);
        args.forEach((args, index) => {
            const mutation = mutations[index];
            try {
                if (mutation.setter) {
                    ctx[mutation.property] =
                        mutation.args[0];
                    return;
                }
                const original = ctx[mutation.property];
                if (mutation.property === 'drawImage' &&
                    typeof mutation.args[0] === 'string') {
                    imageMap.get(event);
                    original.apply(ctx, mutation.args);
                }
                else {
                    original.apply(ctx, args);
                }
            }
            catch (error) {
                errorHandler(mutation, error);
            }
            return;
        });
    });
}

export { canvasMutation as default };
