import { ReplayerEvents } from '../../../../types/dist/types.js';

class MediaManager {
    constructor(options) {
        this.mediaMap = new Map();
        this.metadataCallbackMap = new Map();
        this.warn = options.warn;
        this.service = options.service;
        this.speedService = options.speedService;
        this.emitter = options.emitter;
        this.getCurrentTime = options.getCurrentTime;
        this.emitter.on(ReplayerEvents.Start, this.start.bind(this));
        this.emitter.on(ReplayerEvents.SkipStart, this.start.bind(this));
        this.emitter.on(ReplayerEvents.Pause, this.pause.bind(this));
        this.emitter.on(ReplayerEvents.Finish, this.pause.bind(this));
        this.speedService.subscribe(() => {
            this.syncAllMediaElements();
        });
    }
    syncAllMediaElements(options = { pause: false }) {
        this.mediaMap.forEach((mediaState, target) => {
            this.syncTargetWithState(target);
            if (options.pause) {
                target.pause();
            }
        });
    }
    start() {
        this.syncAllMediaElements();
    }
    pause() {
        this.syncAllMediaElements({ pause: true });
    }
    seekTo({ time, target, mediaState, }) {
        if (mediaState.isPlaying) {
            const differenceBetweenCurrentTimeAndMediaMutationTimestamp = time - mediaState.lastInteractionTimeOffset;
            const mediaPlaybackOffset = (differenceBetweenCurrentTimeAndMediaMutationTimestamp / 1000) *
                mediaState.playbackRate;
            const duration = 'duration' in target && target.duration;
            if (Number.isNaN(duration)) {
                this.waitForMetadata(target);
                return;
            }
            let seekToTime = mediaState.currentTimeAtLastInteraction + mediaPlaybackOffset;
            if (target.loop &&
                duration !== false) {
                seekToTime = seekToTime % duration;
            }
            target.currentTime = seekToTime;
        }
        else {
            target.pause();
            target.currentTime = mediaState.currentTimeAtLastInteraction;
        }
    }
    waitForMetadata(target) {
        if (this.metadataCallbackMap.has(target))
            return;
        if (!('addEventListener' in target))
            return;
        const onLoadedMetadata = () => {
            this.metadataCallbackMap.delete(target);
            const mediaState = this.mediaMap.get(target);
            if (!mediaState)
                return;
            this.seekTo({
                time: this.getCurrentTime(),
                target,
                mediaState,
            });
        };
        this.metadataCallbackMap.set(target, onLoadedMetadata);
        target.addEventListener('loadedmetadata', onLoadedMetadata, {
            once: true,
        });
    }
    getMediaStateFromMutation({ target, timeOffset, mutation, }) {
        var _a, _b, _c, _d, _e;
        const lastState = this.mediaMap.get(target);
        const { type, playbackRate, currentTime, muted, volume, loop } = mutation;
        const isPlaying = type === 0 ||
            (type !== 1 &&
                ((lastState === null || lastState === void 0 ? void 0 : lastState.isPlaying) || target.getAttribute('autoplay') !== null));
        const mediaState = {
            isPlaying,
            currentTimeAtLastInteraction: (_a = currentTime !== null && currentTime !== void 0 ? currentTime : lastState === null || lastState === void 0 ? void 0 : lastState.currentTimeAtLastInteraction) !== null && _a !== void 0 ? _a : 0,
            lastInteractionTimeOffset: timeOffset,
            playbackRate: (_b = playbackRate !== null && playbackRate !== void 0 ? playbackRate : lastState === null || lastState === void 0 ? void 0 : lastState.playbackRate) !== null && _b !== void 0 ? _b : 1,
            volume: (_c = volume !== null && volume !== void 0 ? volume : lastState === null || lastState === void 0 ? void 0 : lastState.volume) !== null && _c !== void 0 ? _c : 1,
            muted: (_d = muted !== null && muted !== void 0 ? muted : lastState === null || lastState === void 0 ? void 0 : lastState.muted) !== null && _d !== void 0 ? _d : target.getAttribute('muted') === null,
            loop: (_e = loop !== null && loop !== void 0 ? loop : lastState === null || lastState === void 0 ? void 0 : lastState.loop) !== null && _e !== void 0 ? _e : target.getAttribute('loop') === null,
        };
        return mediaState;
    }
    syncTargetWithState(target) {
        const mediaState = this.mediaMap.get(target);
        if (!mediaState)
            return;
        const { muted, loop, volume, isPlaying } = mediaState;
        const playerIsPaused = this.service.state.matches('paused');
        const playbackRate = mediaState.playbackRate * this.speedService.state.context.timer.speed;
        try {
            this.seekTo({
                time: this.getCurrentTime(),
                target,
                mediaState,
            });
            if (target.volume !== volume) {
                target.volume = volume;
            }
            target.muted = muted;
            target.loop = loop;
            if (target.playbackRate !== playbackRate) {
                target.playbackRate = playbackRate;
            }
            if (isPlaying && !playerIsPaused) {
                void target.play();
            }
            else {
                target.pause();
            }
        }
        catch (error) {
            this.warn(`Failed to replay media interactions: ${error.message || error}`);
        }
    }
    addMediaElements(node, timeOffset, mirror) {
        if (!['AUDIO', 'VIDEO'].includes(node.nodeName))
            return;
        const target = node;
        const serializedNode = mirror.getMeta(target);
        if (!serializedNode || !('attributes' in serializedNode))
            return;
        const playerIsPaused = this.service.state.matches('paused');
        const mediaAttributes = serializedNode.attributes;
        let isPlaying = false;
        if (mediaAttributes.rr_mediaState) {
            isPlaying = mediaAttributes.rr_mediaState === 'played';
        }
        else {
            isPlaying = target.getAttribute('autoplay') !== null;
        }
        if (isPlaying && playerIsPaused)
            target.pause();
        let playbackRate = 1;
        if (typeof mediaAttributes.rr_mediaPlaybackRate === 'number') {
            playbackRate = mediaAttributes.rr_mediaPlaybackRate;
        }
        let muted = false;
        if (typeof mediaAttributes.rr_mediaMuted === 'boolean') {
            muted = mediaAttributes.rr_mediaMuted;
        }
        else {
            muted = target.getAttribute('muted') !== null;
        }
        let loop = false;
        if (typeof mediaAttributes.rr_mediaLoop === 'boolean') {
            loop = mediaAttributes.rr_mediaLoop;
        }
        else {
            loop = target.getAttribute('loop') !== null;
        }
        let volume = 1;
        if (typeof mediaAttributes.rr_mediaVolume === 'number') {
            volume = mediaAttributes.rr_mediaVolume;
        }
        let currentTimeAtLastInteraction = 0;
        if (typeof mediaAttributes.rr_mediaCurrentTime === 'number') {
            currentTimeAtLastInteraction = mediaAttributes.rr_mediaCurrentTime;
        }
        this.mediaMap.set(target, {
            isPlaying,
            currentTimeAtLastInteraction,
            lastInteractionTimeOffset: timeOffset,
            playbackRate,
            volume,
            muted,
            loop,
        });
        this.syncTargetWithState(target);
    }
    mediaMutation({ target, timeOffset, mutation, }) {
        this.mediaMap.set(target, this.getMediaStateFromMutation({
            target,
            timeOffset,
            mutation,
        }));
        this.syncTargetWithState(target);
    }
    isSupportedMediaElement(node) {
        return ['AUDIO', 'VIDEO'].includes(node.nodeName);
    }
    reset() {
        this.mediaMap.clear();
    }
}

export { MediaManager };
